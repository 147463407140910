import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import box_1_bg from "../../css/images/box_1_bg.png";
import box_2_bg from "../../css/images/box_2_bg.png";
import box_3_bg from "../../css/images/box_3_bg.png";
import box_4_bg from "../../css/images/box_4_bg.png";

import ENDPOINT from "../../constants/api-endpoints";

const Home = () => {
  axios
    .post(ENDPOINT.PAGEDATA, {
      page_category_id: 1,
    })
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  return (
    <>      
      <section className="full_width"  id="scrollContentTarget">
        <div className="container-fluid cont-pad">
          <div className="hm__pg__img">
            <Link to={`/about-us`} target="_blank">
              <img src={box_1_bg} alt="about-us" />
            </Link>
          </div> 
          <div className="hm__pg__img">
            <Link to={`https://www.youtube.com/playlist?list=PLNlPg6Z5G3XQSzcKiQmBjU4SKp7j56j87`} target="_blank">
              <img src={box_2_bg} alt="educational films" />
            </Link>
          </div> 
          <div className="hm__pg__img">
            <Link to={`/getting-started`} target="_blank">
              <img src={box_3_bg} alt="5 easy steps guide" />
            </Link>
          </div> 
          <div className="hm__pg__img">
            <Link to={`https://www.youtube.com/channel/UCkqX5S9up1uFSUer8C0GN6w`} target="_blank">
              <img src={box_4_bg} alt="more videos" />
            </Link>
          </div> 
        </div>
      </section>
    </>
  );
};

export default Home;
