import React, { useState, useEffect } from 'react';
import FormLogo from '../elements/FormLogo';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { P_CLIENT_ID, S_PUBLIC_KEY } from '../../Config/Config';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import formatDate from "../elements/DateFormat";

import ENDPOINT from "../../constants/api-endpoints";

import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import InjectedCheckoutForm from './InjectedCheckoutForm';
import CheckoutForm from './CheckoutForm';

const OrderSummery = () => {
  const navigate = useNavigate();
  const invoiceNumber = localStorage.getItem("invoice_number");
  const localStorageData = JSON.parse(localStorage.getItem("billing_info"));
  const customOrderId = localStorage.getItem("order_id");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [transactionId, setTransactionId] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState();
  const [promoModalShow, setPromoModalShow] = useState(false);
  const [applyPromoCode, setApplyPromoCode] = useState("");

  const closePromoModel = () => {
    setPromoModalShow(false);
  }
  const openPromoModel = () => {
    setPromoModalShow(true);
  }

  useEffect(() => {
    if (customOrderId == null) {
        navigate('/');
    }
  }, []);

  // creates a paypal order
  const createOrder = (data, actions) => {
      return actions.order.create({
          purchase_units: [
              {
                  custom_id: customOrderId,// Add your custom ID here
                  description:  "puppet",
                  amount: {
                      currency_code: "USD",
                      value: localStorageData.totalPrice.toFixed(2),
                  },
              },
          ],
      }).then((transactionId) => {
        setTransactionId(transactionId);
        return transactionId;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
     
      return actions.order.capture().then(function (details) {
          const { payer } = details;
          navigate('/payment-success', { state: { txnId: details.id } });
          // setSuccess(true);
      });
  };

  // capture likely error
  const onError = (data, actions) => {
      navigate('/payment-fail', { state: { message: "An Error occured with your payment " } });
      // setErrorMessage("An Error occured with your payment ");
  };

  const promoFormSubmit = () => {
    if (applyPromoCode === '') {
      toast("Please enter a promo code!");
    } else {
      axios.get(ENDPOINT.APPLY_PROMO_CODE + "/" + applyPromoCode + "/" + customOrderId)
      .then((response) => {
        toast(response.data.data.message);
        if (response.data.data.statusCode === 200) {
          localStorage.setItem("billing_info",response.data.data.data)
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }        
      })
      .catch((error) => {
        if(error.response.status === 401){
          toast("Please Login to the system!");
        }
      });
    }    
  }
  const promoRemove = () => {
    axios.get(ENDPOINT.REMOVE_PROMO_CODE + "/" + customOrderId)
    .then((response) => {
      toast(response.data.data.message);
      localStorage.setItem("billing_info",response.data.data.data)
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    })
    .catch((error) => {
      if(error.response.status === 401){
        toast("Please Login to the system!");
      }
    });
  }

  // ====================
    // STRIPE
  // ====================
  const stripePromise = loadStripe(S_PUBLIC_KEY);
  
  useEffect(() => {
    axios.get(ENDPOINT.STRIPE_KEY + "/" + customOrderId, {})
    .then((response) => {
      setPaymentIntent(response.data.paymentIntent);
    })
    .catch((error) => {
      console.log(error);
    })
  }, []);

const options1 = {
  // passing the client secret obtained from the server
  clientSecret: paymentIntent,
};

  return (
    <>
      <section className="full_width common_heading" id="scrollContentTarget">
      <ToastContainer />
        <div className="container">
          <div className="odr_frm">
              <FormLogo title="Order Summery" />  
              <div className="of_bx_alfa">
                <div className="container">
                  <div className="row orderDetailsBG">
                    {/* BEGIN INVOICE */}
                    <div className="col-sm-12">
                      <div className="grid invoice">
                        <div className="grid-body">
                          <div className="invoice-title">
                            <div className="row">
                              <div className="col-sm-12">
                                <h3 className='colorBlack'> <label>Invoice Number:</label> <span className="small">{invoiceNumber}</span> </h3>
                              </div>
                              <div className="col-sm-6"><label>Purchase Date: </label>{ formatDate(new Date(localStorageData?.purchase_date), 3) }</div>
                              <div className="col-sm-6"><label>Delivery Date: </label>{ formatDate(new Date(localStorageData?.delivery_date), 3) }</div>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-6">
                              <address>
                                <strong>Name: </strong> { localStorageData?.first_name } { localStorageData?.last_name }
                                <br />
                                <strong>Email: </strong> { localStorageData?.email }
                                <br />
                                <abbr title="Phone"><strong>Phone: </strong></abbr> { localStorageData?.phone } { (localStorageData?.cell_phone) ? ', ' + localStorageData?.cell_phone : '' }
                              </address>
                            </div>
                            <div className="col-sm-6">
                              <address>
                                <strong>Address:</strong> { localStorageData?.address }
                                <br />
                                { localStorageData?.state },  { localStorageData?.city },  { localStorageData?.zip }
                                <br />
                              </address>
                            </div>
                          </div>

                          <div className="row">
                            
                            <div className="col-sm-6">
                              <address>                            
                                <strong>Recipient: </strong> { localStorageData?.name_of_recipient }
                                <br />                           
                                <strong>Occasion: </strong> { localStorageData?.occasion }
                                <br />
                                <strong>Special Message:</strong>  { localStorageData?.special_message }
                              </address>
                            </div>
                            { localStorageData?.additional_message &&
                            <div className="col-sm-6">
                              <address>
                                <strong>Additional Information:</strong> { localStorageData?.additional_message }
                              </address>
                            </div>
                            }
                          </div>
                          <hr />
                          <Modal show={promoModalShow} onHide={closePromoModel} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>                            
                            <form className="form-control" id="formContact">
                              <Modal.Header closeButton>
                                <Modal.Title>Apply Promo Code</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                  <input id="myCoupon" type="text" name="myCoupon" required onChange={e => setApplyPromoCode(e.target.value )} placeholder='Enter promocode' />
                              </Modal.Body>
                              <Modal.Footer>
                                <Button variant="success"  onClick={promoFormSubmit} >
                                  Apply
                                </Button>
                              </Modal.Footer>
                            </form>
                          </Modal>
                          <div className="row">
                            <div className='col-md-10'><h3 className='colorBlack'>ORDER SUMMARY</h3></div>
                            <div className='col-md-2 promoCodeBox'>
                              {(localStorageData?.couponDiscount) ? <Link onClick={() => promoRemove()} className='text-danger'>Remove promocode</Link>
                                 :
                                 <Link onClick={() => openPromoModel()}>Apply promocode</Link>
                              }                            
                            </div>
                          </div>                          
                          <div className="row">
                            <div className="col-md-12">                              
                              <table className="table table-striped colorBlack">
                                <thead>
                                  <tr className="line">
                                    <td className=""><strong>Profile</strong></td>
                                    <td className=""><strong>Data</strong></td>
                                    <td className=""><strong>Background</strong></td>
                                    <td className="" style={{ textAlign: "right" }}><strong></strong></td>
                                  </tr>
                                </thead>
                                <tbody>
                                  
                                  <tr>
                                    <td><img src={localStorageData?.puppet_one}  style={{ width: "100px" }}/></td>
                                    <td>Puppet + {localStorageData?.backgroundType.charAt(0).toUpperCase() + localStorageData?.backgroundType.slice(1)}</td>
                                    <td className="">
                                      {localStorageData?.backgroundType === 'video' ? (
                                       <video  width="100" height="100" >
                                          <source src={localStorageData.backgroundImageURL} type="video/mp4" />
                                          Your browser does not support the video tag.
                                        </video>
                                        ) : (
                                          <img src={localStorageData?.backgroundImageURL} style={{ width: "100px" }} />
                                        )}
                                    </td>
                                    <td className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN}{localStorageData?.basicPrice}</td>
                                  </tr>

                                  {localStorageData?.puppet_two && 
                                  <tr>
                                    <td><img src={localStorageData?.puppet_two}  style={{ width: "100px" }}/></td>
                                    <td>Additional Puppet</td>
                                    <td className="">&nbsp;</td>
                                    <td className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN}{localStorageData?.additionalPuppetPrice}</td>
                                  </tr>
                                  }
                                  {localStorageData?.deliverFormatPrice !== 0 && 
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>Video Deliver Option Price</td>
                                      <td className="">{ localStorageData?.deliverFormat.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }</td>
                                      <td className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN}{localStorageData?.deliverFormatPrice}</td>
                                    </tr>
                                  }
                                  {(localStorageData?.deliverFormatPrice !== 0 || localStorageData?.additionalPuppetPrice !== 0) && 
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td><strong>Subtotal</strong></td>
                                      <td className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN}{(localStorageData?.basicPrice + ((localStorageData?.additionalPuppetPrice) ? localStorageData?.additionalPuppetPrice : 0) + (localStorageData?.deliverFormatPrice ? localStorageData?.deliverFormatPrice : 0)) || 0}</td>
                                    </tr>
                                  }
                                  {(localStorageData?.couponDiscount) && 
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td><strong>Promocode Discount(-)</strong></td>
                                      <td className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN}{localStorageData?.couponDiscount || 0}</td>
                                    </tr>
                                  }
                                  {(localStorageData?.taxAmount !== 0) && 
                                    <tr>
                                      <td colSpan={2} ></td>
                                      <td className=""><strong>Taxes( { localStorageData?.taxes } {localStorageData?.taxCalculate == 'percentage' ? '%' :''})</strong></td>
                                      <td className="" style={{ textAlign: "right" }}><strong>{ENDPOINT.DOLLER_SIGN}{localStorageData?.taxAmount.toFixed(2)}</strong></td>
                                    </tr>
                                  }
                                  <tr>
                                    <td colSpan={2}></td>
                                    <td className=""><strong>Total</strong></td>
                                    <td className="" style={{ textAlign: "right" }}><strong>{ENDPOINT.DOLLER_SIGN}{ localStorageData?.totalPrice.toFixed(2) }</strong></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* END INVOICE */}
                  </div>
                </div>

                {show === false && (
                  <div className="d-flex mb-4 mt-4 align-items-center justify-content-center">
                    <input type="button" className="spl_btn"  defaultValue="Checkout" onClick={() => setShow(true)} />
                  </div>            
                )}
                
                {show ? (
                  <>
                    <br /><br />
                    <div className='row mt-6'>
                      <h3 className='colorBlack text-decoration-underline'><strong>Pay With</strong></h3>
                      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                          <Col sm={3}>
                            <Nav variant="pills" className="flex-column">
                              <Nav.Item>
                                <Nav.Link eventKey="first">Credit Card</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="second">Paypal</Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col sm={9}>
                            <Tab.Content>
                              <Tab.Pane eventKey="first">
                              {paymentIntent ? (
                                <Elements stripe={stripePromise} options={options1}>
                                  <CheckoutForm />
                                </Elements>
                              ) : null}
                              </Tab.Pane>
                              <Tab.Pane eventKey="second">
                                <PayPalScriptProvider options={{ "client-id": P_CLIENT_ID }}>
                                  <PayPalButtons style={{ layout: "vertical" }} createOrder={createOrder} onApprove={onApprove} />
                                </PayPalScriptProvider>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </>                  
                 ) : null}
               
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderSummery;
