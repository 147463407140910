import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ENDPOINT from "../../constants/api-endpoints";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import LoginHeaderMenu from "../elements/LoginHeaderMenu";

const PuppetgramCast = () => {
  const [puppetImages, setPuppetImages] = useState([]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    axios
      .get(ENDPOINT.PUPPETGRAM_CAST, {})
      .then((response) => {
        // console.log(response.data.data);
        setPuppetImages(response.data.data);
        // Handle data
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);


  const addFavorite = (id) => {
    const postData = {
      'id' : id,
      'type' : 'puppet',
    };
    const response = axios.post(ENDPOINT.ADD_PUPPET_FAVORITE, postData, {})
    .then((response) => {
      toast(response.data.message);
     
    })
    .catch((error) => {
      if(error.response.status === 401){
        toast("Login first");
      }
    });
  };

  return (
    <>
      <section className="full_width common_heading" id="scrollContentTarget">
        <ToastContainer />
        <div className="container-fluid">          
          <div className="row magin-two-side">
            <h1 className="puppetcast-title">
              <strong>Click on the Pictures to View Our Puppet Cast Profiles</strong>
            </h1>
            {puppetImages.map((images) => (
              <div className="col-sm-4">
                <div className="pfl_pc_bx">
                  <Link to={`/puppet-profile/${images.slug}`}>   
                    <img src={images.background_image_url} alt="thumbnail" />
                  </Link>
                  <p className="title_center">{images.name}</p>
                  {
                    // localStorage.getItem("BearerToken") !== null ? (<button onClick={() => addFavorite(images.id)} > <label>Add Favorite</label> </button> ) : ("")
                  }
                </div>
              
              </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default PuppetgramCast;
