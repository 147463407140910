import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import LoginHeaderMenu from "../elements/LoginHeaderMenu";
import ENDPOINT from "../../constants/api-endpoints";
import cat_title from "../../css/images/cat_title.png";
import footer_logo from '../../css/images/footer_logo.png';
import vgb_text from '../../css/images/vbg-text.png';
import vgb from '../../css/images/vbg.jpg';

const Background = () => {
  const [backgroundImages, setBackgroundImages] = useState([]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    axios.get(ENDPOINT.CATEGORY, {})
      .then((response) => {
        // console.log(response.data.data);
        setBackgroundImages(response.data.data);
        // Handle data
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <section className="full_width common_heading" id="scrollContentTarget">
        <div className="container-fluid cont-pad">
          <div className="row magin-two-side">
            <h1 className="title_center">
              <strong>Click on the Category Option to View the Background<br/>Available to Personalize Your Custom Puppet Video.</strong>
            </h1>
            <h3 className="title_center">
              <strong className="mb-2">In each category you will have the option of</strong><br/><img src={cat_title} />
            </h3>
            {backgroundImages.map((images) => (
              <div className="col-sm-4">
                <div className="pfl_pc_bx no-box-shadow background-bottom">
                  <Link to={`/category/${images.slug}`}>
                    <>
                      <img src={images.background_image_url} alt="thumbnail" />
                      <p className="title_right category_fs">{images.title}</p>
                    </>                    
                  </Link>                  
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="footer_top">
          <div className="container-fluid cont-pad">
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-12 vbg-pad">
                <img src={vgb_text} alt="vgb_text" />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 text-center vbg-mb-25">
                  <div className="vbg_pfl_pc_bx no-box-shadow background-bottom">
                    <Link to={`/video-category`}>
                      <>
                        <img src={vgb} alt="vgb" />
                        <p className="title_center category_fs">Click on the Image to View Options</p>
                      </>                    
                    </Link>                  
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Background;
