import React, { useState, useEffect } from 'react';
import FormLogo from '../elements/FormLogo';
import { useLocation , useNavigate} from "react-router-dom";

const PaymentFail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fail_message = location?.state?.message || undefined;

  useEffect(() => {
    // if (fail_message == undefined) {
    //     navigate('/');
    // }
  }, []);

  return (
    <>
      <section className="full_width common_heading" id="scrollContentTarget">
        <div className="container">
          <div className="odr_frm">
              <FormLogo title="" />  
              <div className="of_bx_alfa">
                <div className="row">
                  <div className="col-sm-12" style={{ textAlign: "center" }}>
                    <h2>Payment Failed!</h2>
                    <p>Something went wrong. Please retry payment or contact to admin!</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentFail;
