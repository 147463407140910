import React, { useState, useEffect } from "react";
import FormLogo from '../elements/FormLogo';
import { Formik, Field, Form, ErrorMessage,} from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ENDPOINT from "../../constants/api-endpoints";
import { useNavigate } from "react-router-dom";

// Form component
const MyAccount = () => {
  const [userRecord, setUserRecord] = useState({});
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [weddingAnniversaryDate, setWeddingAnniversaryDate] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => {
  
    axios
      .get(ENDPOINT.USER_DETAIL, {})
      .then((response) => {
        // console.log(response.data.data);
        if(response.data.status){
          setUserRecord(response.data.data);
          setDateOfBirth(response.data.data.date_of_birth === null ? new Date() : response.data.data.date_of_birth); // Set date of birth from myRecord
          setWeddingAnniversaryDate(response.data.data.wedding_anniversary_date === null ? new Date() : response.data.data.wedding_anniversary_date); // Set wedding anniversary date from myRecord
          formik.setFieldValue("name",response.data.data?.name )
          formik.setFieldValue("email",response.data.data?.email )
          formik.setFieldValue("mobile_number",response.data.data?.mobile_number )
          formik.setFieldValue("gender",response.data.data?.gender === null ? 'male' : response.data.data?.gender )
          formik.setFieldValue("landmark",response.data.data?.landmark )
          formik.setFieldValue("zipcode",response.data.data?.zipcode )
          formik.setFieldValue("country",response.data.data?.country )
          formik.setFieldValue("state",response.data.data?.state )
          formik.setFieldValue("city",response.data.data?.city )
          formik.setFieldValue("address1",response.data.data?.address1 )
          formik.setFieldValue("address2",response.data.data?.address2 )
        }
       
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    mobile_number: Yup.string().required('Mobile number is required'),
    // change_password: Yup.string().required('Password is required'),
    gender: Yup.string().required('Gender is required'),
    date_of_birth: Yup.date().nullable().required('Date of birth is required'),
    wedding_anniversary_date: Yup.date().nullable().required('Wedding anniversary date is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    landmark: Yup.string().required('Landmark is required'),
    zipcode: Yup.string().required('Zipcode is required'),
    address1: Yup.string().required('Address 1 is required'),
    address2: Yup.string().required('Address 2 is required'),
  });
  const formik = useFormik({
    initialValues: {
      name: userRecord?.name || '',
      email: userRecord?.email || '',
      mobile_number: userRecord?.mobile_number || '',
      change_password: '',
      gender: userRecord?.gender || '',
      date_of_birth: dateOfBirth,
      wedding_anniversary_date: weddingAnniversaryDate,
      landmark: userRecord?.landmark || '',
      zipcode: userRecord?.zipcode || '',
      country: userRecord?.country || '',
      state: userRecord?.state || '',
      city: userRecord?.city || '',
      address1: userRecord?.address1 || '',
      address2: userRecord?.address2 || ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
     let updatedValue = values;
     updatedValue.date_of_birth = dateOfBirth;
     updatedValue.wedding_anniversary_date = weddingAnniversaryDate;
    
   
        const response = axios.post(ENDPOINT.UPDATE_USER_DETAIL, updatedValue)
        .then((response) => {
          if(response.data.status){
            toast(response.data.message);
            // navigate("/my-account");
          }else{
            toast(response.data.message);
          }
         
        })
        .catch((error) => {
          toast(response.data.message);
        });
    },
  });
 
  return (
    <>
      <section className="full_width common_heading" id="scrollContentTarget">
         <ToastContainer />
        <div className="container">
        <Formik>
          <div className="odr_frm">
            <FormLogo title="My Account" /> 
            <form onSubmit={formik.handleSubmit}>
              <div className="of_bx_alfa ">
                  <div className="row">
                    <div className="col mb-4">
                      <div className="d-flex">
                        <label>Personal Information</label>
                      </div>
                    </div>
                  </div> 

                  <div className="row mb-4">
                    <div className="col-sm-6">
                      <div className="d-flex mb-4">
                        <label htmlFor="name">Name:</label>
                        <input  type="text"  id="name" name="name" onChange={formik.handleChange} value={formik.values.name}   onBlur={formik.handleBlur} />
                        <ErrorMessage name="name" component="div" />
                      </div>
                      {formik.touched.name && formik.errors.name ? (
                          <div className="text-danger">{formik.errors.name}</div>
                        ) : null}
                    </div>
                    <div className="col-sm-6">
                      <div className="d-flex mb-4">
                        <label htmlFor="email">Email:</label>
                        <input type="text"  id="email" name="email" onChange={formik.handleChange} value={formik.values.email}  onBlur={formik.handleBlur}  />
                      </div>
                      {formik.touched.email && formik.errors.email ? (
                          <div className="text-danger">{formik.errors.email}</div>
                        ) : null}
                    </div>
                   
                  </div>

                  <div className="row mb-4">
                    <div className="col-sm-4">
                      <div className="d-flex mb-4">
                        <label>Mobile Number:</label>
                        <input type="text"name="mobile_number" onChange={formik.handleChange} value={formik.values.mobile_number} onBlur={formik.handleBlur} />
                      </div>
                      {formik.touched.mobile_number && formik.errors.mobile_number ? (
                          <div className="text-danger">{formik.errors.mobile_number}</div>
                        ) : null}
                      
                    </div>

                    <div className="col-sm-4">
                      <div className="d-flex mb-4">
                        <label>Change Password:</label>
                        <input type="password"name="change_password" value={formik.values.change_password} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                      </div>
                      {formik.touched.change_password && formik.errors.change_password ? (
                          <div className="text-danger">{formik.errors.change_password}</div>
                        ) : null}
                    </div>

                    <div className="col-sm-4">
                      <div className="row">
                        <div className="col">
                          <div className="d-flex mb-4">
                            <label>Gender: </label>
                            <input type="radio" id="" name="gender" checked = {formik.values.gender === 'male'} value='male' onChange={formik.handleChange}  onBlur={formik.handleBlur} /> &nbsp; <label>Male </label>
                            <input type="radio" id="" name="gender" checked = {formik.values.gender === 'female'} value='female' onChange={formik.handleChange}  onBlur={formik.handleBlur}/> &nbsp; <label>Female </label>
                          </div>

                          {formik.touched.gender && formik.errors.gender ? (
                          <div className="text-danger">{formik.errors.gender}</div>
                        ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-sm-6">
                      <div className="d-flex mb-4">
                        <label>Date Of Birth:</label>
                        {/* <input type="text" name="date_of_birth"/> */}
                        {/* <DatePicker selected={dateOfBirth} onChange={(date) => setDateOfBirth(date)}  name="date_of_birth" /> */}
                          {/* <DatePicker selected={dateOfBirth} onChange={(date) => setDateOfBirth(date)}  name="date_of_birth" dateFormat="yyyy-MM-dd" /> */}
                          <DatePicker dateFormat="yyyy-MM-dd" selected={dateOfBirth} onChange={(date) => setDateOfBirth(date)}  maxDate={new Date()} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="d-flex mb-4">
                        <label>Wedding Anniversary Date:</label>
                        {/* <DatePicker selected={weddingAnniversaryDate} onChange={(date) => setWeddingAnniversaryDate(date)}  name="wedding_anniversary_date"  dateFormat="yyyy-MM-dd"/> */}
                        <DatePicker dateFormat="yyyy-MM-dd" selected={weddingAnniversaryDate} onChange={(date) => setWeddingAnniversaryDate(date)}  maxDate={new Date()} />

                      </div>
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-sm-4">
                      <div className="d-flex mb-4">
                        <label>Country:</label>
                        <input type="text" name="country" onChange={formik.handleChange} value={formik.values.country} onBlur={formik.handleBlur}/>
                      </div>
                      {formik.touched.country && formik.errors.country ? (
                          <div className="text-danger">{formik.errors.country}</div>
                        ) : null}
                    </div>
                    <div className="col-sm-4">
                      <div className="d-flex mb-4">
                        <label>State:</label>
                        <input type="text" name="state" onChange={formik.handleChange} value={formik.values.state} onBlur={formik.handleBlur} />
                      </div>
                      {formik.touched.state && formik.errors.state ? (
                          <div className="text-danger">{formik.errors.state}</div>
                        ) : null}
                    </div>
                    <div className="col-sm-4">
                      <div className="d-flex mb-4">
                        <label>City:</label>
                        <input type="text" name="city" onChange={formik.handleChange} value={formik.values.city} onBlur={formik.handleBlur}/>
                      </div>
                      {formik.touched.city && formik.errors.city ? (
                          <div className="text-danger">{formik.errors.city}</div>
                        ) : null}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-sm-6">
                      <div className="d-flex mb-4">
                        <label>Landmark:</label>
                        <input type="text" name="landmark"  onChange={formik.handleChange} value={formik.values.landmark} onBlur={formik.handleBlur}/>
                      </div>
                      {formik.touched.landmark && formik.errors.landmark ? (
                          <div className="text-danger">{formik.errors.landmark}</div>
                        ) : null}
                    </div>
                    <div className="col-sm-6">
                      <div className="d-flex mb-4">
                        <label>Zipcode:</label>
                        <input type="text" name="zipcode" onChange={formik.handleChange} value={formik.values.zipcode} onBlur={formik.handleBlur}/>
                      </div>
                      {formik.touched.zipcode && formik.errors.zipcode ? (
                          <div className="text-danger">{formik.errors.zipcode}</div>
                        ) : null}
                    </div>
                    
                  </div>

                  <div className="row mb-4">
                    <div className="col-sm-6">
                      <div className="d-flex mb-4">
                        <label>Address 1:</label>
                        <textarea rows={3} cols={30} name="address1" onChange={formik.handleChange} value={formik.values.address1} onBlur={formik.handleBlur}/>
                      </div>
                      {formik.touched.address1 && formik.errors.address1 ? (
                          <div className="text-danger">{formik.errors.address1}</div>
                        ) : null}
                    </div>
                    <div className="col-sm-6">
                      <div className="d-flex mb-4">
                        <label>Address 2:</label>
                        <textarea rows={3} cols={30} name="address2" onChange={formik.handleChange} value={formik.values.address2} onBlur={formik.handleBlur}/>
                      </div>
                      {formik.touched.address2 && formik.errors.address2 ? (
                          <div className="text-danger">{formik.errors.address2}</div>
                        ) : null}
                    </div>
                  </div>
              </div>
              <div className="col-sm-12">
                  <div className="d-flex mt-4 align-items-center justify-content-center">
                    <input type="submit" defaultValue="Submit" />
                  </div>
                </div>
            </form>
          </div>
          </Formik>
        </div>
      </section>
    </>
  );
};

export default MyAccount;
