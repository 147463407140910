import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import LoginHeaderMenu from "./LoginHeaderMenu";

import logo from "../../css/images/logo-big.png";
import flw_us from "../../css/images/flw_us.png";
import fc_bk_icon from "../../css/images/fc_bk_icon.png";
import yt_icon from "../../css/images/yt_icon.png";
import insta_icon from "../../css/images/insta_icon.png";
import ftr_caption from "../../css/images/ftr_caption.png";
import axios from 'axios';
import ENDPOINT from '../../constants/api-endpoints';

const Header = () => {
  const [socialMediaLink, setSocialMediaLink] = useState({});
  useEffect(() => {
    axios.get(ENDPOINT.SOCIAL_MEDIA_LINK, {})
    .then((response) => {
       // Handle data
       setSocialMediaLink(response.data.data);
       
    })
    .catch((error) => {
      // console.log(error);
    })
  }, []);

  return (
    <>
      <header className="header_main" id="header_main">
        <div className="container-fluid cont-pad">
          <button type="button" className="hamburger">
            <span className="h-top" />
            <span className="h-middle" />
            <span className="h-bottom" />
          </button>
          <nav className="main-nav">
            <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about-us">About Us</Link></li>
                <li><Link to="/puppet-profiles">PuppetGram Cast</Link></li>
                <li><Link to="/category">Backgrounds</Link></li>
                <li><Link to="/order-now">Order Now</Link></li>
                <li><Link to="/getting-started">Getting Started</Link></li>
                <li><Link to="/contact-us">Contact Us</Link></li>
                { localStorage.getItem("BearerToken") === null ? (  <li><Link to="/login">Login/</Link><Link to="/register"> Register</Link></li>  ) : ("") }
            

                { (isMobile && localStorage.getItem("BearerToken") !== null) ? (
                  <>
                    <li>
                      <Link to="/my-favorite">My Favorites</Link>
                    </li>
                    <li>
                      <Link to="/my-account">Account</Link>
                    </li>
                    <li>
                      <Link to="/my-order">My Order</Link>
                    </li>
                    <li>
                      <Link to="/message">Messages</Link>
                    </li>
                    <li>
                      <Link to="/logout">Logout</Link>
                    </li>
                  </>
                  ) : ("")}
            </ul>
          </nav>
          <div className="hdr_scl">
            <h2>
              <img src={flw_us} alt="" />
            </h2>
            <ul>
              <li>
                <a href={ socialMediaLink.facebook } target="_blank">
                  <img src={fc_bk_icon} alt="" />
                </a>
              </li>
              <li>
                <a href={ socialMediaLink.youtube } target="_blank">
                  <img src={yt_icon} alt="" />
                </a>
              </li>
              <li>
                <a href={ socialMediaLink.instgram } target="_blank">
                  <img src={insta_icon} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="logo-bg cont-pad">
            <div className="container-fluid">
                <div className="logo">
                    <Link to={'/'}>   
                        <img src={logo} alt="logo" />
                    </Link> 
                </div>
            </div>
        </div>
        <div className="container-fluid cont-pad">
          <div className="hdr_cptn mb-5"><img src={ftr_caption} alt="caption" /></div>
        </div>
        {
          localStorage.getItem("BearerToken") !== null ? ( <LoginHeaderMenu /> ) : ("")
        }
      </header>
    </>
  );
};

export default Header;
