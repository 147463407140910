import React, { useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import Header from './elements/Header';
import Footer from './elements/Footer';


const Layout = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [height, setHeight] = useState(0)
  
  useEffect(() => {   
    window.addEventListener("scroll", listenToScroll);
    return () => 
       window.removeEventListener("scroll", listenToScroll); 
  }, [])

  const listenToScroll = () => {
    let heightToHideFrom = document.getElementById('header_main').offsetHeight;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);
    if (winScroll > heightToHideFrom) {  
         isVisible && setIsVisible(false);
    } else {
         setIsVisible(true);
    }  
  };
  
  const clickToScroll = () => {
    var element = document.getElementById("scrollContentTarget");
    console.log(element);
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  return (
    <div>
        <Header />
        <Outlet />
        <Footer /> 
        {
        isVisible 
         &&        
          <Link onClick={clickToScroll} className="float bounce" title="Click to see the page content">
            <i className="fa fa-angle-down my-float"></i>
          </Link>
        }   
    </div>
  );
};

export default Layout;