import React, { useState, useEffect } from "react";
import { useParams , Link, useLocation} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
//import LoginHeaderMenu from "../elements/LoginHeaderMenu";
import ENDPOINT from "../../constants/api-endpoints";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import parse from 'html-react-parser';
import SimpleImageSlider from "react-simple-image-slider";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const PuppetProfile = () => {
  const [imageNum, setImageNum] = useState(1);
  const [sliderImages, setSliderImages] = useState([]);
  let { slug } = useParams();
  const location = useLocation();
  const [puppetProfile, setpuppetProfile] = useState({});
  const navigate = useNavigate()
  const [removeFaviorate, setRemoveFaviorate] = useState(0);
  const [loginUserID, setLoginUserID] = useState(localStorage.getItem("loginUserId") || 0);
  const [show, setShow] = useState(false);
  const [video, setVideo] = useState({});
  const handleClose = () => {
    setShow(false);
  }
  const videoShow = (opt,title) => {
    setVideo({ title: title, url: opt });
    console.log(video);
    setShow(true);
  }
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    
    axios
      .get(ENDPOINT.GET_PUPPET_DETAIL + "/" + slug + "/" + loginUserID, {})
      .then((response) => {
        // console.log(response.data);
        setpuppetProfile(response.data.data);
        const images = response.data.data.get_puppet_images.map(image => ({
          url: image.puppet_profile_url
        }));
        setSliderImages(images);
        setRemoveFaviorate(response.data.data.favoritesFlag);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const addFavorite = (id) => {
    const postData = {
      'id' : id,
      'type' : 'puppet',
    };
    const response = axios.post(ENDPOINT.ADD_PUPPET_FAVORITE, postData, {})
    .then((response) => {
      toast(response.data.message);
      setRemoveFaviorate(response.data.deleteFlag);
    })
    .catch((error) => {
      if(error.response.status === 401){
        toast("Login first");
      }
    });
  };

  const hireTalent = (event) => {
    navigate('/order-now',{state:{slug:puppetProfile.slug, selectedImage:localStorage.getItem('selectedOrder') || 'first'}});
  };
  const hireTalentAnonymously = (event) => {
    const varState = {};
    varState.slug = puppetProfile.slug;
    varState.selectedImage = localStorage.getItem('selectedOrder') || 'first';
    // let varState = {slug:puppetProfile.slug, selectedImage:localStorage.getItem('selectedOrder') || 'first'};
    localStorage.setItem("hireTalentAnonymouslyState", JSON.stringify(varState));
    navigate("/login");
  };
  let whatSheContent = '';
  if (puppetProfile.length !== 0) {
    whatSheContent = puppetProfile.what_she;
  }

  const scrollToSliderImages = (event) => {
    event.preventDefault(); // Prevent default anchor behavior
    const sliderImagesSection = document.getElementById('sliderImages');
    if (sliderImagesSection) {
      sliderImagesSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>{ video.title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video width="100%" controls autoPlay="true" >
            <source src={video.url} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <section className="full_width" id="scrollContentTarget">
      <ToastContainer />
        <div className="pd_gap_one" />
        <div className="pd_gap_two" />
      </section>

      <section className="full_width profile_logo_blk_bg">
        <div className="container-fluid cont-pad">
          <div className="pro_top_bar">
            <div className="profile_logo_bx">
              <img src={puppetProfile.profile_image_url} />
            </div>
            <div className="profile_heading_right_block d-flex">
              <div className="p_logo_title">{ puppetProfile.name }</div>
              <div className="p_logo_caption">
                <h4>{ puppetProfile.puppet_introduction_line_1 }</h4>
                <h3>{ puppetProfile.puppet_introduction_line_2 }</h3>
              </div>
            </div>
            {/* <nav className="profile_sub_nav">
              <ul>
                <li>
                  <a href="#">{  puppetProfile.gender ==  'male' ? 'His'  : 'Her'} Story</a>
                </li>
                {sliderImages.length > 0 && (
                  <li>
                      <a href="#" onClick={(event) => scrollToSliderImages(event)}>Photos</a>
                  </li>
                )}
              </ul>
            </nav> */}
          </div>
        </div>
      </section>

      <section className="full_width profile_body_blk">
        <div className="container-fluid cont-pad">
          <div className="row">
            <div className="col-lg-5">
              <div className="profile_big_thumb">
                <img src={ puppetProfile.background_image_url } alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="profile_description">
                <div className="meetProfileMobile">
                  <h2>MEET { puppetProfile.short_name }</h2>
                  <h4>{ puppetProfile.short_description } </h4>
                </div>
                <h3>{ puppetProfile.location }</h3>
                <ul className="d-flex">
                  <li>Likes:</li>
                  <li>{ puppetProfile.likes }</li>
                </ul>
                <ul className="d-flex">
                  <li>Talents:</li>
                  <li>{ puppetProfile.talent }</li>
                </ul>
                <ul className="d-flex">
                  <li>Skills:</li>
                  <li>{ puppetProfile.skill }</li>
                </ul>
                <h2>MEET { puppetProfile.short_name }</h2>
                <h4>{ puppetProfile.short_description } </h4>
              </div>
            </div>
            <div className="col-lg-3">
              <ul className="m-top-30">
                { puppetProfile.sample_video_url && 
                  <li className="c-pointer" onClick={() => videoShow(puppetProfile.sample_video_url, puppetProfile.name)}><a href="#">Watch Video</a></li>                                    
                }
                
                {/* {localStorage.getItem("BearerToken") !== null && (<li onClick={hireTalent}  ><Link >Hire Talent </Link></li>)} */}
                { localStorage.getItem("BearerToken") !== null ? (<li onClick={hireTalent}  ><Link>Hire Talent </Link></li> ) : (<li  onClick={hireTalentAnonymously}><Link >Hire Talent</Link></li>) }
                {removeFaviorate === 0 && localStorage.getItem("BearerToken") !== null && (<li><Link onClick={() => addFavorite(puppetProfile.id)}>Add Favorite</Link></li>)}
                {removeFaviorate === 1 && localStorage.getItem("BearerToken") !== null && (<li><Link onClick={() => addFavorite(puppetProfile.id)}>Remove </Link></li>)}

                

              </ul>
            </div>
          </div>
        </div>
      </section>      
      <section className="full_width prfl_btm_row_one">
        <div className="container-fluid cont-pad">{  puppetProfile.gender ==  'male' ? 'HIS'  : 'HER'  } IDEA OF A GOOD TIME</div>
      </section>

      <section className="full_width prfl_btm_row_two">
        <div className="container-fluid cont-pad">{ puppetProfile.his_idea_of_good_time }</div>
      </section>
      
      <section className="full_width"  id="sliderImages" >
        {sliderImages.length > 0 && (
          <div className="container-fluid cont-pad">  
            <div className="slider-container">
              <SimpleImageSlider
                width={1000}
                height={500}
                images={sliderImages}
                showBullets={true}
                showNavs={true}
                autoPlay={true} 
                autoPlayDelay={3}
                onStartSlide={(index, length) => {
                  setImageNum(index);
                }}
                style={{ maxWidth: '80%', margin: '0 auto' }} // Adjust the maxWidth as needed
              />
            </div>
            {/* <div style={{ fontSize: "1.5rem", textAlign: "center" }}>
              The current image slide number is {imageNum}.
            </div> */}
          </div>
        )}
      </section>
     
     
    </>
  );
};

export default PuppetProfile;
