import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import LoginHeaderMenu from "../elements/LoginHeaderMenu";
import ENDPOINT from "../../constants/api-endpoints";
import { Link } from "react-router-dom";

const MyFavorite = () => {
  const navigate = useNavigate();
  const [myFavoritePuppet, setMyFavoritePuppet] = useState([]);

  useEffect(() => {
    axios
      .get(ENDPOINT.MY_FAVORITE_PUPPET, {})
      .then((response) => {
        setMyFavoritePuppet(response.data.myFavoritePuppet);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <section className="full_width common_heading" id="scrollContentTarget">
        <div className="container-fluid">
          <nav className="main-nav">
            <ul>
              <li>
                <Link to="/my-favorite">Puppet</Link>
              </li>
              <li>
                <Link to="/my-favorite-background" >Background</Link>
              </li>
            </ul>
          </nav>
        
          <div className="row">
            {myFavoritePuppet?. map((data) => (
              <div className="col-sm-4">
                <div className="pfl_pc_bx">
                  <img src={data.file_url} alt="thumbnail" />
                </div>
              </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default MyFavorite;
